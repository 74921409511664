import {
  CERT_2BSvs,
  ISCC_CEPSA,
  ISCC_EU,
  ISCC_EU_VERTEX,
  ISCC_PLUS,
  REDCERT_GMBH,
  SNVS_CEPSA,
  SNVS_VERTEX,
} from "constants/certificateTemplateDetails";
import {
  COUNTRY_AUSTRIA,
  COUNTRY_FINLAND,
  COUNTRY_GERMANY,
  COUNTRY_NORWAY,
  COUNTRY_POLAND,
  COUNTRY_PORTUGAL,
  COUNTRY_SPAIN,
  COUNTRY_SWEDEN,
  COUNTRY_UK,
} from "constants/countryDetails";
import {
  DIV_CODE_COPRO,
  DIV_CODE_GF,
  DIV_CODE_SAF,
} from "constants/divisionDetails";
import {
  getCustomValidationForField,
  getRequiredMessageForField,
} from "./ValidationFields";

export const getCertificationSystemListByCountryAndDivision = (
  country,
  division,
) => {
  switch (country.toLowerCase()) {
    case COUNTRY_SPAIN:
      switch (division) {
        case DIV_CODE_GF:
          return [
            "2BSvs",
            "SNVS Cepsa",
            "SNVS Vertex",
            "ISCC Cepsa",
            "ISCC EU",
            "ISCC EU Vertex",
            "REDcert GmbH",
          ];
        case DIV_CODE_SAF:
          return ["ISCC PLUS", "ISCC EU"];
        case DIV_CODE_COPRO:
        default:
          return ["ISCC EU"];
      }

    case COUNTRY_GERMANY:
      return ["ISCC", "ISCC PLUS", "KZR INiG", "REDcert GmbH", "ISCC EU"];
    case COUNTRY_POLAND:
      return ["Red Cert", "KZR INiG", "ISCC"];

    default:
      return ["ISCC EU"];
  }
};

const productQtyKey = "productQty";
const feedstockQtyKey = "feedstockQty";
const productQtyUomKey = "productQtyUom";
const feedstockQtyUomKey = "feedstockQtyUom";

const getQuantityKeyOrUomKeyByCountryAndDivision = (
  country,
  division,
  getQuantityKey = true,
) => {
  switch (country.toLowerCase()) {
    case COUNTRY_SPAIN:
      switch (division) {
        case DIV_CODE_GF:
        case DIV_CODE_SAF:
          return getQuantityKey ? productQtyKey : productQtyUomKey;
        case DIV_CODE_COPRO:
        default:
          return getQuantityKey ? feedstockQtyKey : feedstockQtyUomKey;
      }
    case COUNTRY_GERMANY:
      switch (division) {
        case DIV_CODE_SAF:
        case DIV_CODE_COPRO:
        default:
          return getQuantityKey ? productQtyKey : productQtyUomKey;
      }
    default:
      switch (division) {
        case DIV_CODE_SAF:
          return getQuantityKey ? productQtyKey : productQtyUomKey;
        default:
          return getQuantityKey ? feedstockQtyKey : feedstockQtyUomKey;
      }
  }
};

export const shouldSplitsBeShownByCountryAndDivision = (
  country,
  division,
  hideSplitsUK,
) => {
  switch (country.toLowerCase()) {
    case COUNTRY_SPAIN:
    case COUNTRY_GERMANY:
      switch (division) {
        case DIV_CODE_COPRO:
          return true;
        case DIV_CODE_GF:
        case DIV_CODE_SAF:
        default:
          return false;
      }
    case COUNTRY_UK:
      if (hideSplitsUK) {
        return false;
      }
      switch (division) {
        case DIV_CODE_GF:
          return true;
        default:
          return false;
      }

    default:
      return false;
  }
};
const COMMON_MANDATORY_FIELDS = [
  "sdNumber",
  "dateOfIssuance",
  "certificationSystem",
  "supplierName",
  "recipientReceiptAddress",
  "materialDispatchDatez",
  "productType",
  "rawMaterialType",
  "productQty",
  "productQtyUom",
  "feedstockQty",
  "feedstockQtyUom",
  "ghgTotal",
  "supplierDispatchAddress",
  "rawMaterialOriginCountryName",
  "productEnergyContent",
  "isccCompliantFlag",
  "euRedCompliantFlag",
  "ghgEmissionPercentageIn",
  "producerInstallationDateFlag",
  "incentiveFlag",
  "originCountryName",
  "splitPercentage",
  "transportationEmissionRoute",
  "mbLocationGroupId",
  "mbBalanceGroupId",
  "previousVersionId",
  "rejectionReason",
];

const getMandatoryFieldsByCountryAndDivisionAndCertificateSystem = (
  country,
  division,
  certificationSystem,
) => {
  switch (country.toLowerCase()) {
    case COUNTRY_GERMANY:
      switch (division) {
        case DIV_CODE_SAF:
          switch (certificationSystem) {
            case ISCC_EU:
            case ISCC_PLUS:
              return [
                "sdNumber",
                "dateOfIssuance",
                "certificationSystem",
                "supplierName",
                "supplierAddress",
                "recipientName",
                "recipientAddress",
                "recipientReceiptAddress",
                "supplierDispatchAddress",
                "materialDispatchDatez",
                "productType",
                "rawMaterialType",
                "productQty",
                "productQtyUom",
                "feedstockQty",
                "feedstockQtyUom",
                "productEnergyContent",
                "ghgTotal",
                "mbLocationGroupId",
                "mbBalanceGroupId",
                "previousVersionId",
                "rejectionReason",
              ];
            case REDCERT_GMBH:
            default:
              return [
                "sdNumber",
                "dateOfIssuance",
                "certificationSystem",
                "supplierName",
                "supplierAddress",
                "recipientName",
                "recipientAddress",
                "recipientReceiptAddress",
                "supplierDispatchAddress",
                "materialDispatchDatez",
                "productType",
                "rawMaterialType",
                "productQty",
                "productQtyUom",
                "feedstockQty",
                "feedstockQtyUom",
                "productEnergyContent",
                "ghgTotal",
                "mbLocationGroupId",
                "mbBalanceGroupId",
                "previousVersionId",
                "rejectionReason",
                "rejectionReasonNotes",
              ];
          }
        case DIV_CODE_COPRO:
        default:
          return COMMON_MANDATORY_FIELDS;
      }
    case COUNTRY_NORWAY:
    case COUNTRY_SWEDEN:
    case COUNTRY_FINLAND:
    case COUNTRY_AUSTRIA:
    case COUNTRY_PORTUGAL:
      switch (division) {
        case DIV_CODE_SAF:
          switch (certificationSystem) {
            case ISCC_PLUS:
              return [
                "sdNumber",
                "dateOfIssuance",
                "certificationSystem",
                "supplierName",
                "supplierAddress",
                "recipientName",
                "recipientAddress",
                "recipientReceiptAddress",
                "supplierDispatchAddress",
                "materialDispatchDatez",
                "productType",
                "rawMaterialType",
                "productQty",
                "productQtyUom",
                "feedstockQty",
                "feedstockQtyUom",
                "productEnergyContent",
                "ghgTotal",
                "supplierDispatchAddress",
                "isccCompliantFlag",
                "euRedCompliantFlag",
                "mbLocationGroupId",
                "mbBalanceGroupId",
                "previousVersionId",
                "rejectionReason",
              ];
            default:
              return [
                "sdNumber",
                "dateOfIssuance",
                "certificationSystem",
                "supplierName",
                "supplierAddress",
                "recipientName",
                "recipientAddress",
                "recipientReceiptAddress",
                "supplierDispatchAddress",
                "materialDispatchDatez",
                "productType",
                "rawMaterialType",
                "productQty",
                "productQtyUom",
                "feedstockQty",
                "feedstockQtyUom",
                "ghgTotal",
                "productEnergyContent",
                "supplierDispatchAddress",
                "isccCompliantFlag",
                "euRedCompliantFlag",
                "rawMaterialOriginCountryName",
                "mbLocationGroupId",
                "mbBalanceGroupId",
                "previousVersionId",
                "rejectionReason",
              ];
          }
        default:
          return COMMON_MANDATORY_FIELDS;
      }
    case COUNTRY_SPAIN:
      switch (division) {
        case DIV_CODE_GF:
          switch (certificationSystem) {
            case ISCC_EU_VERTEX:
              return [
                "sdNumber",
                "dateOfIssuance",
                "certificationSystem",
                "supplierName",
                "recipientReceiptAddress",
                "materialDispatchDatez",
                "productType",
                "rawMaterialType",
                "productQty",
                "productQtyUom",
                "ghgTotal",
                "supplierAddress",
                "recipientAddress",
                "supplierDispatchAddress",
                "rawMaterialOriginCountryName",
                "isccCompliantFlag",
                "euRedCompliantFlag",
                "transportationEmissionRoute",
                "mbLocationGroupId",
                "mbBalanceGroupId",
                "previousVersionId",
                "rejectionReason",
              ];
            case CERT_2BSvs:
            case SNVS_CEPSA:
            case SNVS_VERTEX:
            case ISCC_CEPSA:
            case ISCC_EU:
            case REDCERT_GMBH:
            default:
              return [
                "sdNumber",
                "dateOfIssuance",
                "certificationSystem",
                "supplierName",
                "recipientReceiptAddress",
                "materialDispatchDatez",
                "productType",
                "rawMaterialType",
                "productQty",
                "productQtyUom",
                "feedstockQty",
                "feedstockQtyUom",
                "ghgTotal",
                "supplierDispatchAddress",
                "rawMaterialOriginCountryName",
                "isccCompliantFlag",
                "euRedCompliantFlag",
                "transportationEmissionRoute",
                "mbLocationGroupId",
                "mbBalanceGroupId",
                "previousVersionId",
                "rejectionReason",
              ];
          }
        case DIV_CODE_SAF:
          switch (certificationSystem) {
            case ISCC_PLUS:
              return [
                "sdNumber",
                "dateOfIssuance",
                "certificationSystem",
                "supplierName",
                "supplierAddress",
                "recipientName",
                "recipientAddress",
                "recipientReceiptAddress",
                "supplierDispatchAddress",
                "materialDispatchDatez",
                "productType",
                "rawMaterialType",
                "productQty",
                "productQtyUom",
                "feedstockQty",
                "feedstockQtyUom",
                "ghgTotal",
                "supplierDispatchAddress",
                "isccCompliantFlag",
                "euRedCompliantFlag",
                "rawMaterialOriginCountryName",
                "mbLocationGroupId",
                "mbBalanceGroupId",
                "previousVersionId",
                "rejectionReason",
              ];
            default:
              return [
                "sdNumber",
                "dateOfIssuance",
                "certificationSystem",
                "supplierName",
                "supplierAddress",
                "recipientName",
                "recipientAddress",
                "recipientReceiptAddress",
                "supplierDispatchAddress",
                "materialDispatchDatez",
                "productType",
                "rawMaterialType",
                "productQty",
                "productQtyUom",
                "feedstockQty",
                "feedstockQtyUom",
                "ghgTotal",
                "supplierDispatchAddress",
                "isccCompliantFlag",
                "euRedCompliantFlag",
                "rawMaterialOriginCountryName",
                "mbLocationGroupId",
                "mbBalanceGroupId",
                "previousVersionId",
                "rejectionReason",
              ];
          }
        case DIV_CODE_COPRO:
        default:
          return COMMON_MANDATORY_FIELDS;
      }
    default:
      return COMMON_MANDATORY_FIELDS;
  }
};

const isFieldRequiredByCountryAndDivisionAndCertificateSystem = (
  country,
  division,
  certificationSystem,
  field,
) => {
  const mandatoryFields =
    getMandatoryFieldsByCountryAndDivisionAndCertificateSystem(
      country,
      division,
      certificationSystem,
    );
  return mandatoryFields?.includes(field);
};

export const generateFieldValidationSchema = (applicationData) => {
  const {country, division, certificationSystem, field} = applicationData;
  if (!country || !division || !certificationSystem || !field) return {};

  const isRequired = isFieldRequiredByCountryAndDivisionAndCertificateSystem(
    country,
    division,
    certificationSystem,
    field,
  );
  const requiredMessage = getRequiredMessageForField(field);
  const validationSchema = isRequired ? {required: requiredMessage} : {};

  const customValidationFunction = getCustomValidationForField(field);
  const customValidationSchema = customValidationFunction
    ? customValidationFunction(applicationData, validationSchema)
    : undefined;

  return customValidationSchema ?? validationSchema;
};

export const getQuantityKeyByCountryAndDivision = (country, division) => {
  return getQuantityKeyOrUomKeyByCountryAndDivision(country, division);
};

export const getUomKeyByCountryAndDivision = (country, division) => {
  return getQuantityKeyOrUomKeyByCountryAndDivision(country, division, false);
};
